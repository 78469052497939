import React from "react"
import Link from "../components/common/Link"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Sidan hittades inte" />
    <div className="wp-page  min-h-[70vh] h-full">
      <div className="px-4 lg:px-8 space-y-4 lg:space-y-8 py-4 lg:py-16 max-w-4xl mx-auto">
        <h1>404: Sidan hittades inte</h1>
        <p>
          Testa att börja om på{" "}
          <Link
            to="/"
            title="Startsidan"
            className="underline hover:no-underline"
          >
            startsidan
          </Link>
          .
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
